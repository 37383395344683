<template>
  <section class="container-fluid mt-4 peno-dashboard">
    <vue-element-loading
      v-if="!ready && !error"
      :active="!ready"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <retry v-on:retry="fetchData" v-if="!ready && error"></retry>
    <div class="row mb-3" v-if="ready && !error">
      <div class="col">
        <div class="box fix-col">
          <div class="box-body border-bottom">
            <div class="d-flex flex-wrap justify-content-between">
              <div
                class="d-flex align-items-center justify-content-center text-h5"
              >
                ขั้นตอนการนำเสนอแผนปฏิบัติราชการ
              </div>
              <div>
                <div class="text-center">
                  สถานะความคืบหน้า
                  <span class="font-weight-bold text-red text-h5 ml-2"
                  >ขั้นตอนที่ {{ intro.stage }}</span
                  >
                </div>
                <div
                  class="
                    d-flex
                    flex-nowrap
                    align-items-center
                    justify-content-center
                    plan-progress-container
                  "
                >
                  <div
                    class="plan-progress"
                    :class="{ 'plan-progress-active': intro.stage >= 1 }"
                  ></div>
                  <div
                    class="plan-progress"
                    :class="{ 'plan-progress-active': intro.stage >= 2 }"
                  ></div>
                  <div
                    class="plan-progress"
                    :class="{ 'plan-progress-active': intro.stage >= 3 }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-body border-bottom"
            :class="{ 'stage-completed': intro.stage > 1 }"
          >
            <div class="row">
              <div class="col-8">
                <div class="font-weight-bold stage-text">
                  <span class="text-h5">ขั้นตอนที่ 1</span> : สพจ. ยกร่างแผน
                </div>
                <div class="stage-subtext">
                  พลังงานจังหวัดทบทวนและอนุมัติในร่างแผนปฏิบัติราชการด้านพลังงานของจังหวัด
                </div>
              </div>
              <div class="col-4 text-right">
                <div class="btn-container">
                  <span class="draft-text" v-if="intro.stage == 1">ร่าง</span>
                  <span class="completed-text" v-if="intro.stage > 1"
                  >สำเร็จแล้ว</span
                  >
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="intro.stage1_date && intro.stage == 1"
                >
                  แก้ไขครั้งสุดท้าย {{ intro.stage1_date | thai_date(true) }}
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="intro.stage1_date && intro.stage > 1"
                >
                  สำเร็จเมื่อ {{ intro.stage1_date | thai_date(true) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-body border-bottom"
            :class="{ 'stage-completed': intro.stage > 2 }"
            v-if="intro.stage >= 2"
          >
            <div class="row">
              <div class="col-8">
                <div class="font-weight-bold stage-text">
                  <span class="text-h5">ขั้นตอนที่ 2</span> :
                  คณะทำงานระดับจังหวัดเห็นชอบแผน
                </div>
                <div class="stage-subtext">
                  แสดงความคิดเห็น ประชุมหารือ ด้านแผนปฏิบัติราชการ
                  กับผู้ที่เกี่ยวข้อง และหากมีข้อเสนอแนะ
                  สามารถแก้ไขในแผนปฏิบัติราชการ ได้
                </div>
              </div>
              <div class="col-4 text-right">
                <div class="btn-container">
                  <span class="draft-text" v-if="intro.stage == 2">ร่าง</span>
                  <span class="completed-text" v-if="intro.stage > 2"
                  >สำเร็จแล้ว</span
                  >
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="intro.stage2_date && intro.stage == 2"
                >
                  แก้ไขครั้งสุดท้าย {{ intro.stage2_date | thai_date(true) }}
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="intro.stage2_date && intro.stage > 2"
                >
                  สำเร็จเมื่อ {{ intro.stage2_date | thai_date(true) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-body border-bottom"
            :class="{ 'stage-completed': intro.evaluated }"
            v-if="intro.stage >= 3"
          >
            <div class="row">
              <div class="col-8">
                <div class="font-weight-bold stage-text">
                  <span class="text-h5">ขั้นตอนที่ 3</span> :
                  สถานะการประเมินแผนฯ จาก กยผ(ส่วนกลางประเมินแผนฯ)
                </div>
                <div class="stage-subtext"></div>
              </div>
              <div class="col-4 text-right">
                <div class="btn-container">
                  <span
                    class="draft-text"
                    v-if="intro.stage == 3 && !intro.evaluated"
                  >รอการประเมิน</span
                  >
                  <span class="completed-text" v-else-if="intro.evaluated"
                  >ประเมินแล้ว</span
                  >
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="
                    intro.evaluated_date && intro.stage == 3 && !intro.evaluated
                  "
                >
                  แก้ไขครั้งสุดท้าย {{ intro.evaluated_date | thai_date(true) }}
                </div>
                <div
                  class="text-gray text-small mt-2"
                  v-if="intro.evaluated_date && intro.evaluated"
                >
                  สำเร็จเมื่อ {{ intro.evaluated_date | thai_date(true) }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-footer text-center text-gray py-3">
            <h4 class="d-flex align-items-center justify-content-center">
              สถานะของแผนปฏิบัติราชการ:
              <span class="text-red ml-2" v-if="intro.stage <= 2"
              >รอการส่ง</span
              >
              <span
                class="text-yellow ml-2"
                v-if="intro.stage == 3 && !intro.evaluated"
              >รอการประเมิน</span
              >
              <span
                class="text-green ml-2"
                v-if="intro.stage == 3 && intro.evaluated"
              >ประเมินแล้ว</span
              >
              <span class="evaluate-status" v-if="intro.stage <= 2"></span>
              <span
                class="evaluate-status wait"
                v-if="intro.stage == 3 && !intro.evaluated"
              ></span>
              <span
                class="evaluate-status evaluated"
                v-if="intro.stage == 3 && intro.evaluated"
              ></span>
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center" v-if="ready && !error">
      <div class="year-seperator flex-grow-1"></div>
      <div class="year-select">
        <select name="year" v-model="year">
          <option
            v-for="yearOption in intro.years_list"
            v-bind:key="yearOption.year"
            v-bind:value="yearOption.year"
          >
            {{ yearOption.title }}
          </option>
        </select>
      </div>
      <div class="year-seperator flex-grow-1"></div>
    </div>

    <vue-element-loading
      v-if="!projectsReady && !projectsError"
      :active="!projectsReady"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <retry
      v-on:retry="fetchData"
      v-if="!projectsReady && projectsError"
    ></retry>
    <div class="min-vh-100" v-show="!projectsReady">
      <!--keep scroll position when change year-->
    </div>

    <div class="row mt-3" v-if="projectsReady && !projectsError">
      <div class="col-12 mb-3">
        <button
          v-if="intro.evaluated || latestNationalPlan.year != year"
          @click="showEvaluationSummary()"
          class="ml-2 btn-shadow font-weight-bold text-small px-2 float-right"
        >
          ดูผลประเมิน
        </button>
      </div>
      <div class="col-md-6">
        <div class="box fix-col">
          <div class="box-header">ความคืบหน้าในจังหวัด (toe)</div>
          <div class="box-body">
            <div class="row">
              <div class="col">
                <div class="text-light-ipepp font-weight-bold">
                  {{ $t('energy.ee') }}
                </div>
              </div>
              <div class="col">
                <div class="text-light-ipepp font-weight-bold">
                  {{ $t('energy.re') }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="">
                  {{ projectData.ktoe_ee | auto_number }}
                </h4>
              </div>
              <div class="col">
                <h4 class="">
                  {{ projectData.ktoe_re | auto_number }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-2 mt-md-0">
        <div class="box ml-md-0 fix-col">
          <div class="box-header">งบประมาณ</div>
          <div class="box-body">
            <div class="row">
              <div class="col">
                <div class="text-light-ipepp font-weight-bold">
                  งบประมาณที่ได้รับ จัดสรรจริง
                </div>
              </div>
              <div class="col">
                <div class="text-light-ipepp font-weight-bold">
                  งบประมาณตามแผน
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h4 class="">
                  {{ projectData.budget_actual | money }}
                  <span class="font-weight-normal small-size">บาท</span>
                </h4>
              </div>
              <div class="col">
                <h4 class="">
                  {{ projectData.budget_plan | money }}
                  <span class="font-weight-normal small-size">บาท</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-3" v-if="projectsReady && !projectsError">
      <div class="col">
        <div class="box fix-col">
          <div class="box-header">รายงานความก้าวหน้ารายปี</div>
          <div class="box-body">
            <div
              class="mb-4 pb-4 border-bottom"
              v-for="(project, index) in projectData.projects"
              v-bind:key="project.id"
            >
              <div class="font-weight-bold">
                โครงการที่ {{ parseInt(index) + 1 }}
              </div>
              <div class="text-medium mb-2">
                {{ project.name }}
              </div>
              <div class="d-flex mb-2 align-items-baseline">
                <div class="font-weight-bold mr-3">วงเงิน</div>
                <div class="text-medium">
                  {{ project.project_budgets_expected | auto_number }} บาท
                </div>
              </div>
              <div class="d-flex mb-2 align-items-baseline">
                <div class="font-weight-bold mr-2">แหล่งงบ</div>
                <ul>
                  <li
                    v-for="(source, Index) in project.budget_sources"
                    :key="Index"
                  >
                      <span
                        v-for="listSource in budgetSources"
                        :key="listSource.id"
                      >
                        <span v-if="listSource.id === source.type">
                          {{ listSource.title }}
                          <span v-if="source.other">
                            ({{ source.other }})
                          </span>
                        </span>
                      </span>
                  </li>
                </ul>
              </div>
              <div class="mb-2 align-items-baseline">
                <div class="font-weight-bold">ผลลัพธ์สำคัญ</div>
                <div
                  class="text-medium"
                  v-for="target_result in project['target-results'].data"
                  v-bind:key="target_result.id"
                >
                  {{ projectTargetResultDisplay(target_result) }}
                </div>
              </div>
              <div class="mb-2 align-items-baseline">
                <div class="font-weight-bold">ตัวชี้วัด</div>
                <div
                  class="text-medium"
                  v-for="(indicator, index) in project['project_target_indicators'].data"
                  :key="indicator.id"
                >
                  <div class="font-weight-bold">
                    {{ index + 1 }}.) {{ indicator.target_indicator_name }}
                  </div>
                  <div class="row text-small md:text-medium">
                    <div class="d-flex target-indicator-item">
                      <div class="mr-2 no-wrap">ผลผลิตที่คาดหวัง:</div>
                      <div class="value border-bottom text-center align-items-end">
                        {{ indicator.indicator_value_expected | auto_number(false, 3) }}
                      </div>
                    </div>
                    <div class="d-flex target-indicator-item">
                      <div class="mr-2 no-wrap">ผลผลิตจริง:</div>
                      <div class="value border-bottom text-center align-items-end">
                        {{ indicator.indicator_value_result | auto_number(false, 3) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import Retry from '@/components/Retry.vue';
import ProvinceEvaluationSummary from '@/components/ProvinceEvaluationSummary.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'peno-intro',
  data() {
    return {
      error: false,
      ready: false,
      // hasLastYear: false,
      projectsReady: false,
      projectsError: false,
      year: 2019,
    };
  },
  components: {
    Retry,
  },
  created() {
    this.fetchData();
  },
  watch: {
    year(newVal, oldVal) {
      this.fetchYear(newVal);
    },
  },
  computed: {
    ...mapState('peno', ['intro', 'budgetSources']),
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan', 'nationalPlanByYear']),
    projectData() {
      return this.intro.projects_data[this.year];
    },
  },
  methods: {
    ...mapActions('peno', ['fetchIntro', 'fetchIntroProjects']),
    projectTargetResultDisplay(targetResult) {
      return (
        targetResult.name + ' ' + targetResult.value + ' ' + targetResult.unit
      );
    },
    fetchData() {
      if (this.currentProvince) {
        this.ready = false;
        this.error = false;
        this.fetchIntro({
          province: this.currentProvince,
        })
          .then((data) => {
            this.year = data.years_list[0].year;
            this.ready = true;
            this.error = false;
            this.projectsReady = true;
            this.projectsError = false;
          })
          .catch(() => {
            this.ready = false;
            this.error = true;
            this.projectsReady = false;
            this.projectsError = true;
          });
      }
    },
    fetchYear(year) {
      if (this.intro.projects_data && !this.intro.projects_data[year]) {
        this.projectsReady = false;
        this.projectsError = false;
        this.fetchIntroProjects({
          province: this.currentProvince,
          year: year,
        })
          .then(() => {
            this.projectsReady = true;
            this.projectsError = false;
          })
          .catch(() => {
            this.projectsReady = false;
            this.projectsError = true;
          });
      }
    },
    showEvaluationSummary() {
      this.$modal.show(
        ProvinceEvaluationSummary,
        {
          name: 'evaluation_summary',
          province: this.currentProvince,
          year: this.year,
          nationalPlan: this.nationalPlanByYear(this.year),
        },
        {
          name: 'evaluation_summary',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.target-indicator-item {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.target-indicator-item > .value {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.plan-progress {
  content: '';
  height: 4px;
  width: 2.2rem;
  background-color: lightgray;
  border-radius: 10px;
  margin: 0 0.3rem;
}

.plan-progress-active {
  background-color: $brand-color-500;
}

.stage-btn {
  color: $base-text-color;
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
}

.stage-btn:hover {
  background-color: $gray-color-200;
}

.stage-btn.complete {
  padding-left: 32px;
  padding-right: 32px;
}

.stage-btn.complete:hover {
  border-color: $green-color;
  background-color: $green-color-100;
  color: $green-color;
}

.stage-subtext {
  margin-left: 25px;
}

.stage-text {
  margin-left: 25px;
  position: relative;
  color: $brand-color;
}

.stage-text:before {
  content: '►';
  position: absolute;
  left: -30px;
  top: 6px;
  color: $brand-color;
}

.stage-completed .stage-text {
  color: $gray-color;
}

.stage-completed .stage-text:before {
  content: '✔';
  color: $green-color-500;
}

.stage-completed {
  color: $gray-color;
}

.btn-container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.draft-text {
  padding: 0 1rem;
  background-color: $yellow-color-200;
  color: $orange-color-500;
  font-weight: bold;
  border-radius: 5px;
  height: 32px;
}

.completed-text {
  padding: 0 1rem;
  background-color: $green-color-200;
  color: $green-color;
  font-weight: bold;
  border-radius: 5px;
  height: 32px;
}

.evaluate-status {
  margin-left: 0.5rem;
  display: inline-block;
  background: $red-color-700;
  border-radius: 100%;
  width: 1.1rem;
  height: 1.1rem;
  border: 0.175rem solid $red-color-300;
}

.evaluate-status.wait {
  background: $orange-color-500;
  border-color: $yellow-color-200;
}

.evaluate-status.evaluated {
  background: $green-color-600;
  border-color: $green-color-200;
}

.year-seperator {
  height: 2px;
  background-color: #ddd;
}

.year-select {
  height: 29px;
  overflow: hidden;
  height: 34px;
  background: url('~@/assets/down-arrow.png') no-repeat right center;
  margin: 0 16px;
}

.year-select select {
  background: transparent;
  border: none;
  font-size: 22px;
  height: 34px;
  padding: 5px;
  appearance: none;
  text-indent: 1px;
  padding-right: 25px;
}

@media (max-width: 767px) {
  .target-indicator-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
