<template>
  <div class="box">
    <div class="box-header no-border">
      <div>
        <span class="text-h5 font-weight-bold"
          >ประเมินแผนฯจังหวัด{{ province.name_th }}</span
        >
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
    </div>
    <div class="box-body pt-0" v-if="ready">
      <div class="text-light-ipepp mb-3 ml-2 row">
        <div class="w-100"></div>
        <div class="col-12 col-md-5 d-flex justify-content-between">
          <div>ประเมินครั้งสุดท้ายเมื่อ</div>
          <div>{{ lastEvaluated | thai_date(true) }}</div>
        </div>
      </div>
      <div class="font-weight-bold">ผลประเมิน</div>
      <table class="table table-borderless text-ipepp">
        <thead class="header">
          <tr class="text-small">
            <th scope="col">หัวข้อประเมิน</th>
            <th scope="col">น้ำหนัก</th>
            <th scope="col">คุณภาพ</th>
            <th scope="col">คะแนน</th>
            <th scope="col">ผู้ตรวจ</th>
            <th scope="col">หมายเหตุ</th>
          </tr>
        </thead>
        <tbody class="body text-center">
          <tr v-for="(topic, index) in topics" v-bind:key="topic.id">
            <td class="text-left">
              {{ parseInt(index) + 1 }}. {{ topic.title }}
            </td>
            <td>{{ topic.weight }}</td>
            <td>
              <score v-if="getGrade(topic)" :score="getGrade(topic)"></score>
            </td>
            <td>{{ getScore(topic) }}</td>
            <td>{{ getUser(topic) }}</td>
            <td>
              <div
                v-if="!empty(getNote(topic))"
                class="text-gray text-medium pointer ml-3"
                v-tooltip="{
                  content: getNote(topic),
                }"
              >
                เพิ่มเติม
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold text-right">ผลลัพธ์การประเมิน</td>
            <td></td>
            <td><score :score="reportTotalScore"></score></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="box-body" v-if="!ready">
      <vue-element-loading
        :active="!ready"
        spinner="line-scale"
        color="#ED110E"
        background-color="transparent"
        class="box-body"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { orderBy, sumBy, isEmpty } from 'lodash';
import Score from '@/components/Score.vue';

export default {
  name: 'ProvinceEvaluationSummary',

  components: {
    Score,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    province: {
      type: Object,
      required: true,
    },
    year: {
      required: true,
    },
    nationalPlan: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      ready: false,
      evaluations: null,
    };
  },

  created() {
    this.fetchTopics(this.nationalPlan)
      .then(() => {
        this.fetchProvinceReportEvaluations({
          year: this.year,
          province: this.province,
        })
          .then((data) => {
            this.evaluations = data.reportEvaluations;
            this.reportTotalScore = data.report_total_score;
            this.ready = true;
          })
          .catch((error) => {
            console.error(error);
            this.$toasted.global.display_error({
              message: error,
            });
          });
      })
      .catch((error) => {
        this.$toasted.global.display_error({
          message: error,
        });
      });
  },

  computed: {
    ...mapState('evaluation', ['topics']),
    ...mapGetters(['currentNationalPlan']),
    neededNationalPlan() {
      return this.nationalPlan || this.currentNationalPlan;
    },
    lastEvaluated() {
      const evaluation = this.evaluations.reduce((max, current) => {
        return max.evaluated_at > current.evaluated_at ? max : current;
      }, '');
      return evaluation.evaluated_at;
    },
    sumWeight() {
      return sumBy(this.topics, 'weight');
    },
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReportEvaluations']),
    ...mapActions('evaluation', ['fetchTopics']),
    fetchData() {
      this.ready = false;
    },
    empty(value) {
      return isEmpty(value);
    },
    getEvaluation(topic_id) {
      const evaluation = this.evaluations.find((item) => {
        return item.evaluation_topic_id == topic_id;
      });
      return evaluation || [];
    },
    getGrade(topic) {
      return this.getEvaluation(topic.id).total_score;
    },
    getScore(topic) {
      return this.getEvaluation(topic.id).total_score * topic.weight || '';
    },
    getUser(topic) {
      return (this.getEvaluation(topic.id).user || []).name;
    },
    getNote(topic) {
      return this.getEvaluation(topic.id).note;
    },
  },
};
</script>

<style lang="scss" scoped></style>
